<template>
  <v-app id="adminApp">
    <v-navigation-drawer
      width="280"
      :right="lang == 'ar'"
      app
      v-model="drawer"
      class="bg-secondary"
    >

    <img style="height:60px;width:100%;" src="/assets/logo2.png" alt="">
      <!-- <h2 class="my-4 clr-primary text-center">{{ $t("VisionOptics") }}</h2> -->

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          link
          to="/admin/home"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
        </v-list-item>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("SystemConstant") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/admin/constant"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Constants")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            link
            to="/admin/patient"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Patients")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          

        </v-list-group>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Optometrist") }}</v-list-item-title>
            </v-list-item-content>
          </template>

         <v-list-item
            link
            to="/admin/patient"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Patients")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/optometrists"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Optometrists")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            link
            to="/admin/patient"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Patients")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          

        </v-list-group>

        
        <v-list-group
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("WorkGroup") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/admin/user"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Users")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <!-- <v-list-item
            link
            to="/admin/user_permission"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("UsersPermissions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->


        </v-list-group>


        
          <!-- <v-list-item v-if="user && user.roles && user.roles.includes('users')"
          link
          to="/admin/user"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Users") }}</v-list-item-title>
        </v-list-item> -->

      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="bg-secondary"  :style="lang=='ar'?'direction:rtl;':'direction:ltr;'">
      <v-row>
        <v-col md="12">
          <div>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div class="d-flex align-center" :class="lang == 'ar'?'float-left ml-3': 'float-right mr-3'">
              <v-menu top left dense>
                <template v-slot:activator="{ on, attrs }">
                  
                  <!-- <img style="cursor:pointer;" @click="changeLang('ar')" width="40" height="40" v-if="lang == 'en'" src="/assets/ar.png" alt="">
                  <img style="cursor:pointer;" @click="changeLang('en')" width="40" height="40" v-else src="/assets/en.png" alt="">
                 -->

                  <p v-bind="attrs" v-on="on" class="mx-3 mt-4 clr-primary">
                    {{ user.admin.name }}
                  </p>

                  <!-- <v-img
                    v-bind="attrs"
                    v-on="on"
                    class="clickable"
                    :src="user.image"
                    style="
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                      margin-left: auto;
                      margin-right: auto;
                      vertical-align: middle;
                    "
                  /> -->
                </template>
                <v-list dense>
                  <!-- <v-list-item dense>
                    <v-btn small color="success" text
                      >{{ $t("Profile") }}
                      <v-icon class="my-0 mr-4" dense>mdi-account</v-icon>
                    </v-btn>
                  </v-list-item> -->
                  <v-list-item dense>
                    <v-btn small color="danger" @click="logout" text
                      >{{ $t("Logout") }}

                      <v-icon class="my-0 mr-7" dense>mdi-logout</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import config from "@/config";
export default {
  name: "App",
  data: () => ({
    lang: localStorage.getItem('lang') || 'en',
    drawer: true,
    config: config,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : [],
  }),
  methods: {
    changeLang(lang) {
      localStorage.setItem('lang', lang);
      window.location.reload();
    },
    goTo(page) {
      window.open(page)
    },
    logout() {
      this.$store.dispatch("auth/logout", this.object).then((result) => {
        this.notifySuccess("Logout", result);
        this.logoutSuccess();
      }).catch(err => {
         this.logoutSuccess();
      })
    },
  },
};
</script>
